function App() {
  return (
    <div className="bg-primary text-primary-content">

      <div className='max-w-6xl mx-auto h-screen flex flex-col bg-primary'>

        <div className='flex w-full justify-start items-center py-4'>
          <div className='w-10'><img class='App-logo' src='logo.png' /></div>
          <div className='text-primary-content font-bold text-2xl'><p>Pracomierz</p></div>
        </div>


        <div className='flex flex-col justify-between items-center flex-grow bg-primary'>
          <div className='flex w-full md:justify-between items-center justify-start  flex-col md:flex-row gap-y-8 gap-x-8 p-4 flex-1'>
            <div className="flex flex-col md:justify-start md:items-start space-y-8 md:justify-center items-center bg-ble-500 mt-8 md:mt-0">
              <div className='md:text-7xl text-5xl text-center md:text-left text-primary-content font-black max-w-lg tracking-tight'>
                <h1 className="leading-[1.2]">Prosty dziennik Twojej <span className=' leading-24 bg-secondary px-4 md:pb-4 pb-3 pt-0 max-w-xs rounded-md rotate-[-5deg] inline-block text-primary'>pracy</span></h1>
              </div>
              <h2 className="text-primary-content tracking-wide max-w-md text-center md:text-left text-lg">
                Czas pracy, zarobki urlopy, notatki, bonusy, zaliczki, statystyki i raporty PDF/CSV w jednym miejscu.
              </h2>
              <div className="flex">
                <a href='https://play.google.com/store/apps/details?id=com.paragrafxv.workhours&hl=pl&gl=PL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='pobierz z Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png' className="w-48" /></a>
              </div>
            </div>
            <div className="w-full h-full flex-1 overflow-hidden justify-center  flex" >

              <img src='/phone.png' alt='phone' className='w-auto object-contain md:max-w-[400px] max-w-[300px] hover:scale-105 transition-all duration-300' />
            </div>
          </div>

          <div className='flex flex-col justify-between items-center text-xs space-y-2 py-2'>
            <div className='App-copyright'>
              <a href="/policy.html">Polityka prywatności</a>
            </div>

            <div className='App-copyright'>
              <p>© 2025 Pracomierz. All rights reserved.</p>
            </div>
          </div>

        </div>



      </div>
    </div>
  );
}

export default App;
